#root {
  /*padding:1rem;*/
  width:99%;
  /*padding:30px;*/
}

#stairConfiguration .list-group-item {
  cursor: pointer;
  text-align: center;
  padding: 0.55rem 0.85rem;
}
#stairConfiguration .list-group-item.active {
  color: #000;
  background: rgb(240,240,240);
  background: -moz-linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0f0f0",endColorstr="#ffffff",GradientType=1);
}
#stairConfiguration .list-group-item:hover {
  color: #000;
  background: rgb(240,240,240);
  background: -moz-linear-gradient(0deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f7f7",endColorstr="#ffffff",GradientType=1);
}
#stairConfiguration .list-group-item img {
  width:100%;
  max-width:225px;
  margin-bottom:15px;
}
/* .custom-control-label.size-sm::before, .custom-control-label.size-sm::after {
  width: .7rem;
  height: .7rem;
  top: .5rem;
} */
.input-group {
  margin-bottom:5px;
}

ul.error-list {
list-style:none;
margin:0;padding:0;
}
ul.error-list > li {
  margin-bottom:8px;
}

.show {
  display: inherit !important;
}
.hide {
  display: none !important;
}

.step {
  width: 36px;
  height: 36px;
  line-height: 27px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  border: 3px solid #14679e;
  text-align: center;
  background-color: white;
  z-index: 99;
  position: relative;
  color: #5491d2;
}
.fa-file-download {
  color: indianred;
}

.text-white {
  color:#fff;

}

img.config {
  max-width:75% !important;
}